import * as React from "react";
import { Link } from "gatsby";
import Header from 'src/components/articles-page/Header';
import Footer from "src/components/Footer";
import "../styles/index.scss"



const NotFoundPage = () => {
  return (
    <div className="page-404-wrapper">
      <div className="layout-container page-404">
        <Header />
        <main>
          <div className="content-404">
            <h2 className="content-title mb-2">
              Whoops! Page not found
            </h2>
            <p className="content-text mb-5">
              The page you tried to view may have been moved or deleted.
            </p>
            <button className="btn articles__btn" type="button" >
              <Link to="/">Back to home</Link>
            </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default NotFoundPage
